import React, { useCallback, useEffect, useRef, useState } from "react";
import data from "@emoji-mart/data";
import Picker from "@emoji-mart/react";
import style from "./Chat.module.css";
import { IoIosSend } from "react-icons/io";
import { GrAttachment } from "react-icons/gr";
import { FaRegFileAlt } from "react-icons/fa";
import { PiWarningCircle } from "react-icons/pi";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import { IoArrowBack } from "react-icons/io5";
import { useTranslation } from "react-i18next";
import { t } from "i18next";
import { CgCloseO } from "react-icons/cg";
import Profile from "../Profile/Profile";
import ReservisionApi from "../../helper/Api";
import Toster from "../../Common/Toster/Toster";
import { useDispatch, useSelector } from "react-redux";
import { STATUS_CODES } from "../../Utils/StatusCode";
import { formatDateTime, formatUpdatedDate } from "../../Common/DateFormate";
import ChatLoader from "../../Common/loader/ChatLoader";
import {
  formatPhoneNumberWithMask,
  removeExtraPlusSigns,
} from "../../Common/commonFunction/CommonFunction";
import { MdErrorOutline, MdOutlineEmojiEmotions } from "react-icons/md";
import TemplateModel from "./TemplateModal";
import { useForm } from "react-hook-form";
import ImageModal from "./ImageModel";
import debounce from "lodash.debounce";
import MessageLoader from "../../Common/loader/MessageLoader";
import stringLength from "string-length";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import { setCustomerId } from "../../Redux/Slices/userSlices";

const Message = ({
  text,
  senderType,
  time,
  Failed,
  timestamp,
  senderName,
  imgUrl,
  isMedia,
  onImageClick,
}) => {
  const { t } = useTranslation();

  const messageClassName =
    senderType == "2" ? style.userMessage : style.botMessage;

  const popover = (
    <Popover id="popover-basic">
      <Popover.Body>{t("FAILED_TOTIL")}</Popover.Body>
    </Popover>
  );

  const popoverTime = (
    <Popover id="popover-advance">
      <Popover.Body>{time}</Popover.Body>
    </Popover>
  );

  return (
    <>
      <div className={`${style.message} ${messageClassName}`}>
        <OverlayTrigger placement="top" overlay={popoverTime}>
          <h6 className={style.time} style={{ cursor: "pointer" }}>
            {senderType == "1" && senderName && <span>{senderName} • </span>}
            {timestamp}
          </h6>
        </OverlayTrigger>

        {isMedia === "1" ? (
          Failed ? (
            <MdErrorOutline className={style.FailedImageContainer} />
          ) : (
            <img
              src={imgUrl}
              alt="Attached Image"
              className={style.messageImageContainer}
              onClick={() => onImageClick(imgUrl)} // Add onClick event
            />
          )
        ) : null}
        {text?.length > 0 && (
          <p
            style={{ whiteSpace: "pre-wrap" }}
            dangerouslySetInnerHTML={{ __html: text }} // Use dangerouslySetInnerHTML
          ></p>
        )}
      </div>
      {Failed && (
        <OverlayTrigger placement="left" overlay={popover}>
          <p className={style.failedText} style={{ cursor: "pointer" }}>
            {Failed}
          </p>
        </OverlayTrigger>
      )}
    </>
  );
};

const Chat = ({
  contact,
  onBackClick,
  onSuccess,
  selectedIndexId,
  handleGetContact,
  showSearchInput,
  setActiveChat,
}) => {
  const {
    formState: { errors, isValid },
  } = useForm({
    mode: "onChange",
  });

  const dispatch = useDispatch();

  const currentTimeZoneState = useSelector(
    (state) => state.user.currentTimeZoneState
  );

  const authToken = useSelector((state) => state.user.userToken);

  const chatUserName = useSelector((state) => state.user.userContactList);

  const newMsgNotification = useSelector(
    (state) => state.user.newMsgNotification
  );

  console.log("newMsgNotification", newMsgNotification)

  const [loadMoreScroll, setLoadMoreScroll] = useState(0);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [newMessage, setNewMessage] = useState("");
  const [messageSent, setMessageSent] = useState(false);

  const [attachedFile, setAttachedFile] = useState(null);

  const fileInputRef = useRef(null);

  const [showProfile, setShowProfile] = useState(false);
  const [selectedEmoji, setSelectedEmoji] = useState("");
  const [messageLength, setMessageLength] = useState(0);
  const [countMessage, setCountMessage] = useState(0);
  const MAX_CHARACTERS_PER_MESSAGE = 160;
  const [chatHeight, setChatHeight] = useState(window.innerHeight - 136);
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 600);
  const [userName, setUserName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedFilen, setSelectedFilen] = useState(null);
  const [imgError, setImgError] = useState("");
  const [messages, setMessages] = useState([]);

  const [limit] = useState(20);
  const [offset, setOffset] = useState(0);
  const [loadMore, setLoadMore] = useState(true);
  const [apiIndex, setApiIndex] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const [loadMoreLoader, setLoadMoreLoader] = useState(false);

  const [showTemplateList, setShowTemplateList] = useState(false);
  const [isImageModalOpen, setIsImageModalOpen] = useState(false);
  const [modalImageUrl, setModalImageUrl] = useState("");
  const [isSending, setIsSending] = useState(false);
  const [rows, setRows] = useState(1);
  const maxRows = 12;

  const chatContainerRef = useRef(null);
  const textareaRef = useRef(null);

  const [inboxList, setInboxList] = useState([]);
  const [selectedInbox, setSelectedInbox] = useState("");
  const [isIndexId, setIndexId] = useState("");

  const generatePseudoId = (message) => {
    return `${message.from}-${message.to}-${message.crd}`;
  };

  // Extract the first and last names based on the current contact's information
  const currentContactName = chatUserName?.find(
    (user) =>
      user.countryCode === contact.countryCode &&
      user.contactNo === contact.contactNo
  );

  const displayName =
    currentContactName?.firstName && currentContactName?.lastName
      ? `${currentContactName?.firstName} ${currentContactName?.lastName}`
      : contact?.firstName && contact?.lastName
      ? `${contact?.firstName} ${contact?.lastName}`
      : formatPhoneNumberWithMask(contact.countryCode, contact.contactNo);

  // manage chat list height
  useEffect(() => {
    const handleResize = () => {
      setChatHeight(window.innerHeight - 134);
    };

    window?.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  useEffect(() => {
    if (newMsgNotification) {
      console.log("contact",contact )
      if (contact?.contactNo && contact?.inboxId) {
        if (
          removeExtraPlusSigns(contact?.countryCode + contact?.contactNo) ===
            removeExtraPlusSigns(
              newMsgNotification.countryCode + newMsgNotification?.contactNo
            ) &&
          contact?.inboxId === newMsgNotification?.inboxId
        ) {
          console.log("newMsgNotification in", newMsgNotification)
          setMessages((pre) => [...pre, newMsgNotification]);
        }
      }
    }
  }, [newMsgNotification]);

  // show contact popup
  const OpenProfile = () => {
    setShowProfile(!showProfile);
  };

  const handleEmojiSelect = (emoji) => {
    setNewMessage((prevInput) => prevInput + emoji.native);
    // setInput((prevInput) => prevInput + emoji.native);
    setShowEmojiPicker(false);
  };

  // send message
  const sendMessage = () => {
    if (isSending) return;
    const trimmedMessage = newMessage.trim();

    // Check if the trimmed message or attachedFile exists
    if (trimmedMessage || attachedFile) {
      let messageToSend = newMessage;

      if (selectedEmoji) {
        messageToSend += selectedEmoji;
      }

      setAttachedFile(null);
      setIsSending(true);
      onSubmit(messageToSend);
      // resetTextarea()
    }
  };

  const handleAttachmentClick = () => {
    fileInputRef.current.click();
  };

  const handleMessageChange = (event) => {
    const textareaLineHeight = 18;
    const previousRows = event.target.rows;
    event.target.rows = 1;

    const currentRows = Math.floor(
      event.target.scrollHeight / textareaLineHeight
    );
    if (currentRows === previousRows) {
      event.target.rows = currentRows;
    }

    if (currentRows >= maxRows) {
      event.target.rows = maxRows;
      event.target.scrollTop = event.target.scrollHeight;
    } else {
      event.target.rows = currentRows;
    }

    setNewMessage(event.target.value);
    setRows(currentRows < maxRows ? currentRows : maxRows);
  };

  useEffect(() => {
    const handleEnterPress = (event) => {
      if (event.key === "Enter") {
        const newRows = rows + 1;
        if (newRows <= maxRows) {
          setRows(newRows);
        }
      }
    };

    const textarea = textareaRef.current;
    textarea?.addEventListener("keydown", handleEnterPress);

    return () => {
      textarea.removeEventListener("keydown", handleEnterPress);
    };
  }, [rows]);

  const handleToggleEmojiPicker = () => {
    setShowEmojiPicker(!showEmojiPicker);
  };

  // message length count
  useEffect(() => {
    setMessageLength(stringLength(newMessage));
    const messageCount = Math.ceil(
      stringLength(newMessage) / MAX_CHARACTERS_PER_MESSAGE
    );
    setCountMessage(messageCount);
  }, [newMessage]);

  useEffect(() => {
    if (newMessage.trim() === "") {
      setMessageLength(0);
      setCountMessage(0);
    }
  }, [newMessage]);

  const scrollToBottom = () => {
    if (chatContainerRef.current) {
      let previousScrollHeight =
        chatContainerRef.current.scrollHeight - loadMoreScroll;
      chatContainerRef.current.scroll({
        top: previousScrollHeight,
        behavior: "auto", // This creates a smooth effect
      });
    }
  };

  // Conversation detail api
  const handleConversationDetail = async (
    isLoadMore = false,
    showLoader = true,
    resetDataTrue = false,
    inboxId = ""
  ) => {
    let currentOffset = isLoadMore ? offset : 0;

    if (resetDataTrue == true) {
      currentOffset = 0;
    }

    if (currentOffset === 0) {
      if (showLoader == true) {
        setIsLoading(true);
      }
    }
    if (currentOffset !== 0 && loadMore === true) {
      if (showLoader == true) {
        setLoadMoreLoader(true);
      }
    }

    try {
      let newMessages = [];

      // Function to fetch messages with a given offset
      const fetchMessages = async (offset) => {
        const userData = {
          countryCode: contact?.countryCode,
          contactNo: contact?.contactNo,
          limit: limit,
          offset: offset,
          inboxId,
        };
        return await ReservisionApi.getConversationDetail(authToken, userData);
      };

      if (currentOffset === 0 || resetDataTrue == true) {
        // Fetch first batch of messages
        const firstBatchData = await fetchMessages(0);
        if (
          firstBatchData?.data?.status === "success" ||
          firstBatchData?.status === "success"
        ) {
          const firstBatchMessages = [...firstBatchData.messages].reverse();

          // Fetch second batch of messages
          const secondBatchData = await fetchMessages(20);
          if (
            secondBatchData?.data?.status === "success" ||
            secondBatchData?.status === "success"
          ) {
            const secondBatchMessages = [...secondBatchData.messages].reverse();

            // Combine messages
            newMessages = [...secondBatchMessages, ...firstBatchMessages];
            setOffset(secondBatchData.nextOffset);
            setMessages(newMessages);
            setLoadMore(firstBatchData.loadMoreBtn);
            setApiIndex(firstBatchData.inboxId);

            // Update selected inbox based on new inboxId to reset dropdown
            if (firstBatchData.inboxId) {
              const selectedInboxFilter = inboxList.find(
                (inbox) => inbox.id === firstBatchData.inboxId
              );
              setSelectedInbox(selectedInboxFilter);
              setIndexId(selectedInboxFilter?.id || "");
            }
          } else {
            console.error("Second batch of messages failed:", secondBatchData);
          }
        }
      } else {
        const loadMoreData = await fetchMessages(currentOffset);
        if (
          loadMoreData?.data?.status === "success" ||
          loadMoreData?.status === "success"
        ) {
          const loadMoreMessages = [...loadMoreData.messages].reverse();

          // Handle duplicates and update messages
          const existingMessageIds = new Set(
            messages.map((msg) => generatePseudoId(msg))
          );
          const filteredMessages = loadMoreMessages.filter(
            (msg) => !existingMessageIds.has(generatePseudoId(msg))
          );
          newMessages = [...filteredMessages, ...messages];

          setMessages(newMessages);
          setOffset(loadMoreData.nextOffset);
          setLoadMore(loadMoreData.loadMoreBtn);
          setApiIndex(loadMoreData.inboxId);

          // Update selected inbox based on new inboxId to reset dropdown
          if (loadMoreData.inboxId) {
            const selectedInboxFilter = inboxList.find(
              (inbox) => inbox.id === loadMoreData.inboxId
            );
            setSelectedInbox(selectedInboxFilter);
            setIndexId(selectedInboxFilter?.id || "");
          }
        } else {
          console.error("Load more messages failed:", loadMoreData);
          Toster(loadMoreData?.message, "error");
        }
      }
    } catch (error) {
      console.error("Failed to fetch messages", error);
    } finally {
      setIsLoading(false);
      setLoadMoreLoader(false);
    }
  };

  useEffect(() => {
    setLoadMoreScroll(0);
    setMessages([]);
    !contact.isNewUser &&
      handleConversationDetail(false, true, true, contact?.inboxId);
    setNewMessage("");
  }, [contact]);

  // send message api
  const onSubmit = async (messageToSend) => {
    let Postdata = new FormData();
    Postdata.append("countryCode", contact?.countryCode);
    Postdata.append("contactNo", contact?.contactNo);
    Postdata.append(
      "countryIsoCode",
      contact?.countryIsoCode ? contact?.countryIsoCode : ""
    );
    Postdata.append("msgSenderType", 1);
    Postdata.append("message", messageToSend);
    Postdata.append("isMedia", attachedFile ? 1 : 0);
    Postdata.append("media", attachedFile);
    Postdata.append("inboxId", isIndexId);

    setIsLoading(true);
    try {
      let responeData = await ReservisionApi.fetchSendMessage(
        authToken,
        Postdata
      );
      setIsSending(false);
      setIsLoading(false);
      if (
        responeData?.data?.status === "success" ||
        responeData?.status === "success"
      ) {
        setRows(1);
        setNewMessage("");
        setAttachedFile(null);
        setSelectedEmoji("");

        const newMsg = {
          crd: responeData?.data?.crd,
          crdOrg: responeData?.data?.crdOrg,
          from: responeData?.data?.from,
          imgUrl: responeData?.data?.imgUrl,
          isMedia: responeData?.data?.isMedia,
          message: responeData?.data?.message,
          senderName: responeData?.data?.senderName,
          senderType: responeData?.data?.senderType,
          status: responeData?.data?.status,
          to: responeData?.data?.to,
        };

        setMessages((pre) => [...pre, newMsg]);
        setMessageSent(true); // Set flag to true
        onSuccess();
        setMessageLength(0);
        dispatch(setCustomerId(responeData?.customerId));
      } else if (
        responeData?.data?.responseCode === STATUS_CODES.INVALID_TOKEN ||
        responeData?.responseCode === STATUS_CODES.INVALID_TOKEN
      ) {
        window.location.href = process.env.REACT_APP_REDIRECT_URL;
      } else {
        Toster(responeData?.message, "error");
      }
    } catch (error) {
      setIsSending(false);
      setIsLoading(false);
      console.error("Failed to send message", error);
    }
  };

  useEffect(() => {
    if (messageSent && chatContainerRef.current) {
      setMessageSent(false);
    }
  }, [messages, messageSent]);

  const sendTemplate = () => {
    setShowTemplateList(true);
  };

  const handleClose = () => {
    setShowTemplateList(false);
  };

  const setTemplateMessage = (message) => {
    const formattedMessage = message.replace(/<br\s*\/?>/gi, "\r\n");
    setNewMessage(formattedMessage);

    // Adjust the textarea height
    setTimeout(() => {
      const textareaLineHeight = 18;
      const textarea = textareaRef.current;
      if (textarea) {
        const currentRows = Math.floor(
          textarea.scrollHeight / textareaLineHeight
        );

        if (currentRows >= maxRows) {
          textareaRef.current.rows = maxRows;
          textareaRef.current.scrollTop = textareaRef.current.scrollHeight;
        } else {
          textareaRef.current.rows = currentRows;
        }
        setRows(currentRows < maxRows ? currentRows : maxRows);

        // Adjust chat height based on textarea height
        const textareaHeight = textarea.scrollHeight;
        setChatHeight(window.innerHeight - textareaHeight - 110);
      }
    }, 0);
  };

  const handleImageClick = (url) => {
    setModalImageUrl(url);
    setIsImageModalOpen(true);
  };

  const handleScroll = useCallback(
    debounce((e) => {
      const element = e.target;
      if (element.scrollTop === 0 && loadMore && !isLoading) {
        !contact.isNewUser && handleConversationDetail(true, true, false, contact?.inboxId);
        setLoadMoreScroll(element.scrollHeight);
      }
    }, 200),
    [messages, loadMore, isLoading, contact?.inboxId]
  );

  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current?.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (chatContainerRef.current) {
        chatContainerRef.current.removeEventListener("scroll", handleScroll);
      }
    };
  }, [handleScroll, newMessage]);

  useEffect(() => {
    const textarea = textareaRef.current;
    if (textarea) {
      const lineHeight = parseInt(getComputedStyle(textarea).lineHeight);
      const textareaHeight = textarea.scrollHeight;
      const newRows = Math.min(
        Math.floor(textareaHeight / lineHeight),
        maxRows
      );

      if (newRows < maxRows) {
        setRows(newRows);
        setChatHeight(window.innerHeight - textareaHeight - 110);
      } else if (newRows === maxRows) {
        setRows(newRows);
      }
    }
  }, [newMessage]);

  useEffect(() => {
    if (apiIndex) {
      const selectedInboxFilter = inboxList.find(
        (inbox) => inbox.id === apiIndex
      );
      setSelectedInbox(selectedInboxFilter);
      setIndexId(selectedInboxFilter?.id || "");
    }
  }, [apiIndex, inboxList]);

  // Fetch inbox list
  const handleIndexLists = () => {
    setIsLoading(true);
    ReservisionApi.getCompanyInboxList(authToken)
      .then((res) => {
        if (res?.data?.status === "success" || res?.status === "success") {
          Toster(res?.message, "success");
          const inboxList = res.data.company_inbox_list;
          setInboxList(inboxList);
          // Set the selected inbox based on selectedIndexId or default to the first inbox
          const selectedInboxFilter = selectedIndexId
            ? selectedIndexId &&
              inboxList.find((inbox) => inbox.id === selectedIndexId)
            : inboxList[0];

          setSelectedInbox(selectedInboxFilter);
          setIndexId(selectedInboxFilter?.id || "");
        } else {
          Toster(res?.message, "error");
        }
      })
      .catch((error) => {
        Toster("Error fetching inbox list", "error");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  // Fetch inbox list on component mount
  useEffect(() => {
    handleIndexLists();
  }, [selectedIndexId]);

  // This effect updates the selected inbox based on the apiIndex
  useEffect(() => {
    if (inboxList.length > 0) {
      const selectedInbox = selectedIndexId
        ? inboxList.find((inbox) => inbox.id === selectedIndexId)
        : inboxList[0];

      setSelectedInbox(selectedInbox);
      setIndexId(selectedInbox?.id || "");
    }
  }, [selectedIndexId, inboxList]);

  const handleSelect = (eventKey) => {
    const [id, name, contact, dialCode, number] = eventKey.split(" | ");
    setSelectedInbox({ id, name, contact, dialCode, number });
    setIndexId(id);

    handleConversationDetail(false, true, true, id);

    // setIndexId(selectedInboxFilter?.id || "");
  };

  return (
    <div className={style.chatContainer}>
      <h2>
        {isSmallScreen && <IoArrowBack onClick={onBackClick} />}
        <span>
          {displayName}
          <PiWarningCircle
            onClick={OpenProfile}
            style={{ cursor: "pointer" }}
          />
        </span>
      </h2>

      <div className={style.ChatProfile}>
        <div className={style.ChatMessage}>
          <div style={{ height: `${chatHeight}px`, overflowY: "auto" }}>
            <div
              ref={chatContainerRef}
              className={style.chat}
              onScroll={handleScroll}
            >
              {isLoading && offset === 0 && <ChatLoader />}
              {loadMoreLoader && <MessageLoader />}
              {!isLoading && (
                <>
                  {messages.map((msg, index) => (
                    <Message
                      key={index}
                      sender={msg.senderType == 1 ? "user" : "bot"}
                      senderType={msg.senderType}
                      text={msg.message}
                      senderName={msg.senderName}
                      time={formatDateTime(
                        msg.crdOrg,
                        currentTimeZoneState?.timeZoneTxt
                      )}
                      timestamp={formatUpdatedDate(
                        msg.crdOrg,
                        currentTimeZoneState?.timeZoneTxt
                      )}
                      Failed={msg.status === "Failed" ? "Failed" : ""}
                      imgUrl={msg.imgUrl}
                      isMedia={msg.isMedia}
                      onImageClick={handleImageClick}
                    />
                  ))}
                </>
              )}
            </div>
          </div>

          <div className={style.Section}>
            {attachedFile && (
              <div className={style.ImageContainer}>
                <img
                  src={URL.createObjectURL(attachedFile)}
                  alt="Attached Image"
                  style={{
                    minWidth: "100px",
                    width: "100%",
                    height: "80px",
                    objectFit: "cover",
                  }}
                />
                <CgCloseO
                  className={style.CloseIcon}
                  onClick={() => setAttachedFile(null)}
                />
              </div>
            )}
            <div className="messageSection">
              <div className="chat-input">
                <textarea
                  ref={textareaRef}
                  rows={rows}
                  value={newMessage}
                  onChange={handleMessageChange}
                  placeholder="Type a message..."
                  className="custom-input-textarea"
                  style={{ lineHeight: "18px" }}
                />
              </div>
              {showEmojiPicker && (
                <Picker data={data} onEmojiSelect={handleEmojiSelect} />
              )}

              <div className={style.ButtonGroup}>
                <div className={style.iconss}>
                  <MdOutlineEmojiEmotions
                    onClick={handleToggleEmojiPicker}
                    size={23}
                  />
                  <GrAttachment onClick={handleAttachmentClick} size={18} />
                  <FaRegFileAlt size={18} onClick={sendTemplate} />

                  {/* Inbox selction dropdown button */}
                  <div className="sendIndexButton">
                    {contact?.isNewUser || apiIndex === undefined ? (
                      // Interactive Dropdown when condition is met
                      <DropdownButton
                        id="dropdown-basic-button"
                        title={
                          selectedInbox ? (
                            <div className={style.spanBox}>
                              <h1>{selectedInbox.name}</h1>
                              <span>
                                {formatPhoneNumberWithMask(
                                  selectedInbox.dialCode,
                                  selectedInbox.number
                                )}
                              </span>
                            </div>
                          ) : (
                            <div></div>
                          )
                        }
                        drop="up"
                        onSelect={handleSelect}
                      >
                        {inboxList?.map((inbox) => (
                          <Dropdown.Item
                            key={inbox.id}
                            eventKey={`${inbox.id} | ${inbox.name} | ${inbox.contact} | ${inbox.dialCode} | ${inbox.number}`}
                            as="button"
                          >
                            <label
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "flex-start",
                              }}
                            >
                              <div className={style.spanBox}>
                                {inbox.name}
                                <span style={{ color: "#757c80" }}>
                                  {formatPhoneNumberWithMask(
                                    inbox.dialCode,
                                    inbox.number
                                  )}
                                </span>
                              </div>
                              <input
                                type="radio"
                                name="inboxRadio"
                                checked={selectedInbox?.id === inbox.id}
                                onChange={() =>
                                  handleSelect(
                                    `${inbox.id} | ${inbox.name} | ${inbox.contact} | ${inbox.dialCode} | ${inbox.number}`
                                  )
                                }
                                style={{
                                  marginTop: "5px",
                                  marginLeft: "20px",
                                  accentColor: "#EB263E",
                                }}
                              />
                            </label>
                          </Dropdown.Item>
                        ))}
                      </DropdownButton>
                    ) : apiIndex && selectedInbox ? (
                      // Allow read-only view to still have an interactive dropdown
                      <DropdownButton
                        id="dropdown-readonly"
                        title={
                          <div
                            className={style.spanBox}
                            style={{ cursor: "pointer" }}
                          >
                            <h1>{selectedInbox?.name || ""}</h1>
                            <span>
                              {selectedInbox?.dialCode
                                ? formatPhoneNumberWithMask(
                                    selectedInbox?.dialCode,
                                    selectedInbox?.number
                                  )
                                : ""}
                            </span>
                          </div>
                        }
                        drop="up"
                        onSelect={handleSelect}
                      >
                        {inboxList?.map((inbox) => (
                          <Dropdown.Item
                            key={inbox.id}
                            eventKey={`${inbox.id} | ${inbox.name} | ${inbox.contact} | ${inbox.dialCode} | ${inbox.number}`}
                            as="button"
                          >
                            <label
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "flex-start",
                              }}
                            >
                              <div className={style.spanBox}>
                                {inbox.name}
                                <span style={{ color: "#757c80" }}>
                                  {formatPhoneNumberWithMask(
                                    inbox.dialCode,
                                    inbox.number
                                  )}
                                </span>
                              </div>
                              <input
                                type="radio"
                                name="inboxRadio"
                                checked={selectedInbox?.id === inbox.id}
                                onChange={() =>
                                  handleSelect(
                                    `${inbox.id} | ${inbox.name} | ${inbox.contact} | ${inbox.dialCode} | ${inbox.number}`
                                  )
                                }
                                style={{
                                  marginTop: "5px",
                                  marginLeft: "20px",
                                  accentColor: "#EB263E",
                                }}
                              />
                            </label>
                          </Dropdown.Item>
                        ))}
                      </DropdownButton>
                    ) : null}
                  </div>

                  <input
                    ref={fileInputRef}
                    type="file"
                    accept="image/*"
                    style={{ display: "none" }}
                    onChange={(e) => {
                      setAttachedFile(e.target.files[0]);
                      e.target.files[0] && setNewMessage("");
                    }}
                  />
                </div>
                <div className={style.SendButton}>
                  <div className={style.characterCount}>
                    <span>{messageLength}</span>
                    <span>
                      {countMessage} {t("MSG_COUNT")}
                    </span>
                  </div>
                  <button
                    onClick={sendMessage}
                    disabled={
                      isLoading ||
                      isSending ||
                      (!newMessage.trim() && !attachedFile)
                    }
                    className={`${
                      newMessage.trim() || attachedFile
                        ? style.typing
                        : style.empty
                    }`}
                  >
                    <IoIosSend size={20} style={{ marginRight: "5px" }} />{" "}
                    {t("SEND")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {showProfile && (
          <Profile
            userName={userName}
            setUserName={setUserName}
            phoneNumber={phoneNumber}
            setPhoneNumber={setPhoneNumber}
            email={email}
            setEmail={setEmail}
            selectedFile={selectedFile}
            setSelectedFile={setSelectedFile}
            selectedFilen={selectedFilen}
            setSelectedFilen={setSelectedFilen}
            imgError={imgError}
            setImgError={setImgError}
            userdata={contact}
            hideDropdown={true}
            onSuccess={onSuccess}
            handleGetContact={handleGetContact}
            showSearchInput={showSearchInput}
            setActiveChat={setActiveChat}
            handleConversationDetail={handleConversationDetail}
          />
        )}
      </div>
      <TemplateModel
        showTemplateList={showTemplateList}
        handleClose={handleClose}
        setTemplateMessage={setTemplateMessage}
        contactId={contact?.customerId}
      />
      <ImageModal
        isOpen={isImageModalOpen}
        onRequestClose={() => setIsImageModalOpen(false)}
        imgUrl={modalImageUrl}
      />
    </div>
  );
};

export default Chat;
